import React from 'react'


import Rotas from './routes';
import './assets/styles/global.css';




function App() {
  return (
    <Rotas />
  )
}

export default App;

